<template>
  <div>
    <event-notification></event-notification>
  </div>
</template>
<script>
import EventNotification from './EventNotification.vue';

export default {
  components: {
    EventNotification,
  },
};
</script>
